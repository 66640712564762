import * as Sentry from '@sentry/react';
import { get, set } from 'lodash';

const load = () => {
  let state = {};
  try {
    state = JSON.parse(localStorage.getItem('tv.frm') || '{}');
  } catch (e) {
    Sentry.captureException(e);
  }
  return state;
};

const setItem = (key: string, value: any) => {
  const state = load();
  set(state, `${key}`, value);
  localStorage.setItem('tv.frm', JSON.stringify(state));
  return null;
};

const getItem = (key: string) => {
  const state = load();
  return get(state, `${key}`);
};

export { setItem, getItem };
