import classNames from 'classnames';
import { useEffect, useState } from 'react';

export default function Toggle({
  checked = false,
  label,
  onChange,
  labelClassNames = '',
}: any) {
  const [enabled, setEnabled] = useState(!!checked);

  useEffect(() => {
    setEnabled(checked);
  }, [checked]);

  return (
    <div className="relative flex flex-col justify-center overflow-hidden mb-[0.5rem]">
      <div className="flex justify-between items-center gap-4">
        <span className={classNames('text-sm font-normal', labelClassNames)}>
          {label}
        </span>
        <label className="inline-flex relative items-center">
          <input
            data-testid={`${label}-input`}
            type="checkbox"
            className="sr-only peer"
            checked={enabled}
            readOnly
          />
          <div
            onClick={() => {
              setEnabled(!enabled);
              onChange && onChange(!enabled);
            }}
            data-testid={`${label}-toggle`}
            className="cursor-pointer mr-1 w-8 h-5 bg-gray-200 rounded-full peer peer-focus:ring-blue-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:-left-0 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600 peer-checked:after:border-blue-600"
          ></div>
        </label>
      </div>
    </div>
  );
}
