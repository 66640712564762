import { EyeIcon } from '@heroicons/react/24/outline';
import Button from '@apps/form/src/components/Button';
type PreviewButtonType = {
  isUpdateLoading: boolean;
  onclick?: () => void;
};

const PreviewButton = ({ isUpdateLoading, onclick }: PreviewButtonType) => (
  <Button
    Icon={EyeIcon}
    onClick={() => onclick && onclick()}
    text={'Preview'}
    isLoading={isUpdateLoading}
    buttonstyles={'text-blue-700  border-[1.5px] mr-[1.37rem] '}
    textStyles={
      'text-blue-700 text-sm flex align-middle justify-center items-center font-medium'
    }
  />
);

export default PreviewButton;
