import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetFormDraftQuery,
  usePublishFormMutation,
  useUpdateFormMutation,
} from '@apps/form/src/store/services/updateFormBuilder';
import { RootState } from '@apps/form/src/store';
import { updateFormState } from '@apps/form/src/store/actions';
import { ROUTE_TYPE } from '@apps/form/src/utils/constant';
import { publishFormState } from '@apps/form/src/Pages/Editor/utils';
import FormBuilder from '@apps/form/src/containers/FormBuilder';
import BlockNoteRenderer from '@apps/form/src/containers/BlockNoteRenderer';
import FormHeader from '@apps/form/src/components/FormHeader';
import Loader from '@apps/form/src/components/Loader';

export interface Data {
  name: string;
  type: string;
  form_version_id: number;
  structure: { doc: Array<Record<string, any>>; button?: any };
}

export type FormStateType =
  | 'builder'
  | 'renderer'
  | 'share'
  | 'results'
  | 'settings'
  | null;

const Form = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const formState = useSelector((state: RootState) => state?.form?.formState);

  const [updateForm, { isLoading }] = useUpdateFormMutation();
  const [publishForm, { isLoading: isPublishLoading }] =
    usePublishFormMutation();

  const {
    data,
    status,
    refetch: fetchFormDraft,
  } = useGetFormDraftQuery({
    id,
  });

  const {
    name,
    type,
    structure: formContent,
  }: Data = (data || {
    name: '',
    type: '',
    structure: { doc: [{}] },
  }) as Data;

  const setFormState = (state: FormStateType) =>
    dispatch(updateFormState(state));

  return (
    <div className="h-screen flex flex-col">
      {formState !== ROUTE_TYPE.RENDERER && (
        <>
          <FormHeader
            isUpdateLoading={isLoading}
            isPublishLoading={isPublishLoading}
            formState={formState}
            setFormState={setFormState}
            publishFormState={() => publishFormState(id, publishForm, navigate)}
            formName={name}
            fetchFormDraft={fetchFormDraft}
          />
          <div className={'w-full h-[0.0625rem] border border-[#E5E7EB]'}></div>
        </>
      )}
      {status === 'pending' ? (
        <div className="h-full flex items-center justify-center grow">
          <Loader />
        </div>
      ) : formState === ROUTE_TYPE.RENDERER ? (
        <BlockNoteRenderer
          setFormState={setFormState}
          formContent={formContent}
          formType={type}
        />
      ) : (
        <FormBuilder
          updateForm={updateForm}
          id={id}
          formContent={formContent}
          formType={type}
        />
      )}
    </div>
  );
};

export default Form;
