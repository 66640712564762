import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { debounce } from 'lodash';
import {
  BlockNoteSchema,
  defaultBlockSpecs,
  filterSuggestionItems,
} from '@blocknote/core';
import {
  SideMenuController,
  SuggestionMenuController,
  getDefaultReactSlashMenuItems,
  useCreateBlockNote,
} from '@blocknote/react';
import { BlockNoteView } from '@blocknote/mantine';
import '@blocknote/core/fonts/inter.css';
import '@blocknote/mantine/style.css';
import {
  faAt,
  faCalendarAlt,
  faCameraViewfinder,
  faCheckSquare,
  faChevronDown,
  faCodeFork,
  faDotCircle,
  faEdit,
  faFileAlt,
  faHashtag,
  faSquareDashed,
  faSquareDashedCirclePlus,
  faUpload,
} from '@fortawesome/pro-regular-svg-icons';
import {
  blockGroups,
  blocksLabels,
  customBlocksTypes,
  insertBlock,
} from '@apps/form/src/components/Tools/CustomBlocks/utils';
import {
  CheckBoxBlock,
  DatePickerBlock,
  DropDownBlock,
  EmailBlock,
  FileUploadBlock,
  InputTextBlock,
  LogicBlock,
  NumberBlock,
  PhotoCaptureBlock,
  RadioBlock,
  SectionEndBlock,
  SectionStartBlock,
  TextAreaBlock,
} from '@apps/form/src/components/Tools/CustomBlocks';
import SideMenu from '@apps/form/src/components/SideMenu';

export interface EditorInstance {
  getBlockEditor: () => BlockEditor;
}

export type BlockEditor = {
  document: Array<{
    props: { attrkey?: string; value?: any };
  }>;
};

interface BlockNoteEditorProps {
  onChange?: () => void;
  formContent: any[];
  isEditable: boolean;
  selectFieldTypes: any[];
}

export const schema = BlockNoteSchema.create({
  blockSpecs: {
    ...defaultBlockSpecs,
    input: InputTextBlock,
    number: NumberBlock,
    email: EmailBlock,
    checkbox: CheckBoxBlock,
    textarea: TextAreaBlock,
    select: DropDownBlock,
    radio: RadioBlock,
    datepicker: DatePickerBlock,
    file: FileUploadBlock,
    logic: LogicBlock,
    photoCapture: PhotoCaptureBlock,
    sectionStart: SectionStartBlock,
    sectionEnd: SectionEndBlock,
  },
});

const BlockNoteEditor = forwardRef<unknown, BlockNoteEditorProps>(
  ({ formContent, isEditable, onChange, selectFieldTypes }, ref) => {
    const menuFieldsRef = useRef<any>(null);
    const {
      input,
      number,
      email,
      checkbox,
      textarea,
      select,
      radio,
      datepicker,
      file,
      logic,
      photoCapture,
      sectionStart,
      sectionEnd,
    } = customBlocksTypes;

    const {
      inputLabel,
      numberLabel,
      emailLabel,
      checkboxLabel,
      textareaLabel,
      selectLabel,
      radioLabel,
      datepickerLabel,
      fileLabel,
      logicLabel,
      sectionStartLabel,
      sectionEndLabel,
      videoLabel,
      audioLabel,
      othersLabel,
      paragraphLabel,
      tableLabel,
      textLabel,
      heading3Label,
      photoCaptureLabel,
    } = blocksLabels;

    const {
      StructureBlocks,
      TextBlocks,
      AdvancedBlocks,
      BasicBlocks,
      Headings,
    } = blockGroups;

    const creatBlockNoteObj = {
      schema,
      initialContent: formContent,
    };

    const editor = useCreateBlockNote(creatBlockNoteObj);
    const inputTextBox = insertBlock(editor, input, inputLabel, faEdit);
    const numberBox = insertBlock(editor, number, numberLabel, faHashtag);
    const emailBox = insertBlock(editor, email, emailLabel, faAt);
    const checkboxBlock = insertBlock(
      editor,
      checkbox,
      checkboxLabel,
      faCheckSquare,
    );
    const textAreaBox = insertBlock(editor, textarea, textareaLabel, faFileAlt);
    const dropDownBox = insertBlock(editor, select, selectLabel, faChevronDown);
    const radioBox = insertBlock(editor, radio, radioLabel, faDotCircle);
    const fileBox = insertBlock(editor, file, fileLabel, faUpload);
    const datePickerBox = insertBlock(
      editor,
      datepicker,
      datepickerLabel,
      faCalendarAlt,
    );
    const photoCaptureBox = insertBlock(
      editor,
      photoCapture,
      photoCaptureLabel,
      faCameraViewfinder,
    );
    const sectionStartBox = insertBlock(
      editor,
      sectionStart,
      sectionStartLabel,
      faSquareDashedCirclePlus,
      StructureBlocks,
    );
    const sectionEndBox = insertBlock(
      editor,
      sectionEnd,
      sectionEndLabel,
      faSquareDashed,
      StructureBlocks,
    );
    const logicBox = insertBlock(
      editor,
      logic,
      logicLabel,
      faCodeFork,
      AdvancedBlocks,
    );

    const getCustomBlockMenuItems = () => [
      inputTextBox,
      textAreaBox,
      radioBox,
      checkboxBlock,
      dropDownBox,
      fileBox,
      numberBox,
      emailBox,
      datePickerBox,
      photoCaptureBox,
    ];

    const getCustomSlashMenuItems = (editor: typeof schema.BlockNoteEditor) => {
      try {
        const customItems = getCustomBlockMenuItems();
        const defaultItems = getDefaultReactSlashMenuItems(editor)
          .filter(
            (i) =>
              i.title !== videoLabel &&
              i.title !== audioLabel &&
              i?.group !== othersLabel,
          )
          .map((i) => {
            const updatedItem = { ...i };
            if (i.group === Headings) {
              updatedItem.group = TextBlocks;
            }
            if (i.title === paragraphLabel) {
              updatedItem.title = textLabel;
              updatedItem.group = TextBlocks;
            }
            if (i.title === tableLabel) {
              updatedItem.group = TextBlocks;
            }

            if (i.group === BasicBlocks) {
              updatedItem.group = TextBlocks;
            }
            return updatedItem;
          });
        const heading3Index = defaultItems.findIndex(
          (item) => item.title === heading3Label,
        );
        const textItemIndex = defaultItems.findIndex(
          (item) => item.title === textLabel,
        );
        const tableItemIndex = defaultItems.findIndex(
          (item) => item.title === tableLabel,
        );

        if (heading3Index !== -1) {
          if (textItemIndex !== -1) {
            const [textItem] = defaultItems.splice(textItemIndex, 1);
            const targetIndexForText = heading3Index + 1;
            defaultItems.splice(targetIndexForText, 0, textItem);

            if (tableItemIndex !== -1) {
              const tableItemIndexAdjusted = defaultItems.findIndex(
                (item) => item.title === tableLabel,
              );
              const [tableItem] = defaultItems.splice(
                tableItemIndexAdjusted,
                1,
              );
              const targetIndexForTable = targetIndexForText + 1;
              defaultItems.splice(targetIndexForTable, 0, tableItem);
            }
          }
        }

        return [
          ...customItems,
          ...defaultItems,
          sectionStartBox,
          sectionEndBox,
          logicBox,
        ];
      } catch (e) {
        console.error(e);
        return [
          ...getCustomBlockMenuItems(),
          ...getDefaultReactSlashMenuItems(editor),
          sectionStartBox,
          sectionEndBox,
          logicBox,
        ];
      }
    };

    const onBlockNoteChange = () => {
      if (onChange) onChange();
    };

    const debouncedBlockNoteChange = debounce(onBlockNoteChange, 1000);

    useImperativeHandle(ref, () => ({
      getBlockEditor: () => editor,
    }));

    return (
      <BlockNoteView
        editor={editor}
        slashMenu={false}
        onChange={debouncedBlockNoteChange}
        editable={isEditable}
        theme="light"
        sideMenu={false}
      >
        <SideMenuController
          sideMenu={(props) => (
            <SideMenu
              {...props}
              editor={editor}
              menuFieldsRef={menuFieldsRef}
              selectFieldTypes={selectFieldTypes}
            />
          )}
        />
        <SuggestionMenuController
          triggerCharacter="/"
          getItems={async (query) =>
            filterSuggestionItems(getCustomSlashMenuItems(editor), query)
          }
        />
      </BlockNoteView>
    );
  },
);

export default BlockNoteEditor;
