import React from 'react';

interface ValidationState {
  triggerInvalid: boolean;
  message: string;
}

type SetValidation = React.Dispatch<React.SetStateAction<ValidationState>>;

export const handleInputValidation = (
  event:
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLTextAreaElement>,
  setValidation: SetValidation,
) => {
  const isValid = event?.target?.validity?.valid;
  if (isValid) {
    setValidation((prev) => ({ ...prev, triggerInvalid: false }));
  }
  if (!isValid && event?.target?.validity?.valid === false) {
    setValidation((prev) => ({ ...prev, triggerInvalid: true }));
  }
};

export const handleInvalidField = (
  event:
    | React.InvalidEvent<HTMLInputElement>
    | React.InvalidEvent<HTMLTextAreaElement>,
  setValidation: SetValidation,
) => {
  setValidation((prev) => ({ ...prev, triggerInvalid: true }));
  event.preventDefault();
  const invalidFields = document.querySelectorAll('input:invalid');

  if (invalidFields.length > 0) {
    const firstInvalidField = invalidFields[0] as HTMLElement;
    if (event?.target === firstInvalidField) {
      firstInvalidField.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }
};
