import React, { ReactElement } from 'react';
import {
  cloneDeep,
  flatMap,
  forEach,
  get,
  isEqual,
  map,
  set,
  times,
} from 'lodash';
import classNames from 'classnames';
import { getElement } from '@apps/form/src/utils/getElement';
import { getItem } from '@apps/form/src/utils/store';
import { DEVICE_TYPE } from '@apps/form/src/utils/constant';
import { customBlocksTypes } from '@apps/form/src/components/Tools/CustomBlocks/utils';
import { schema } from '@apps/form/src/components/BlockNoteEditor';
import {
  ContentItem,
  DeviceType,
  FieldData,
  HtmlBlock,
  LogicProps,
} from '../types';
import { postFormFile } from './formDataHelpers';
import { applyConditionalLogic } from './conditionalLogic';

export const onChangeInBlock = (
  type: string,
  res: any,
  attrkey: string,
  setFormFileData: (val: FieldData) => void,
  setFormData: (val: FieldData) => void,
  logicsRef: React.MutableRefObject<LogicProps[]>,
  blockId: string,
) => {
  if (type === customBlocksTypes.file) {
    postFormFile(res, attrkey, setFormFileData, blockId);
  } else {
    setFormData({ [blockId]: res });
    applyConditionalLogic(logicsRef.current, attrkey, res?.value);
  }
};

export const initBlocksRender = async (
  content: Array<Record<string, any>>,
  setFormFileData: (val: FieldData) => void,
  setFormData: (val: FieldData) => void,
  editor: typeof schema.BlockNoteEditor,
  logicsRef: React.MutableRefObject<LogicProps[]>,
) => {
  const groupedBlocks: any[] = [];
  const instanceSections: FieldData = {};

  let groupedBlockProps = {};
  let htmlBlocks: HtmlBlock[] = [];
  let layout: number[] = [];
  const conditionalLogics = [];

  for (const item of content) {
    const { type, props, id } = (item as ContentItem) || {};
    let blockElement = null;

    // if logic block store logics and continue loop
    if (type === customBlocksTypes.logic) {
      conditionalLogics.push(props);
      continue;
    }

    // if sectionGroup block store existing group, initialize htmlBlocks, layout, and continue loop
    if (type === customBlocksTypes.sectionStart) {
      htmlBlocks.push(React.cloneElement(getElement(type), { ...props }));
      layout.push(1);
      groupedBlocks.push({ htmlBlocks, layout, props: groupedBlockProps });
      [htmlBlocks, layout, groupedBlockProps] = [[], [], props];
      continue;
    }

    // if sectionGroupEnd block store group, initialize htmlBlocks, layout and continue loop
    if (type === customBlocksTypes.sectionEnd) {
      groupedBlocks.push({ htmlBlocks, layout, props: groupedBlockProps });
      if (get(groupedBlockProps, 'toggleState.maxInstances'))
        instanceSections[groupedBlocks.length - 1] = {
          htmlBlocks,
          layout,
          maxInstances: get(groupedBlockProps, 'maxInstances'),
          instances: 0,
        };
      [htmlBlocks, layout, groupedBlockProps] = [[], [], {}];
      continue;
    }

    if (typeof type === 'string' && type in customBlocksTypes) {
      const { attrkey, label, hide } = props || {};

      const element = React.cloneElement(getElement(type, id), {
        ...props,
        id,
        customBlockType: type,
        onChange: (res: any) =>
          attrkey &&
          id &&
          onChangeInBlock(
            type,
            res,
            attrkey,
            setFormFileData,
            setFormData,
            logicsRef,
            id,
          ),
      });
      layout.push(props?.layout?.value || 1);
      blockElement = (
        <div
          id={attrkey || label}
          className={classNames(
            hide && 'hidden',
            'w-full p-0.5 mb-3',
            props?.rowSpan && `row-span-${props.rowSpan}`,
          )}
        >
          {element}
        </div>
      );
    } else {
      blockElement = await editor?.blocksToFullHTML([item]);
      layout.push(1);
    }
    htmlBlocks.push(blockElement);
  }

  // group the last left out blocks
  htmlBlocks.length > 0 &&
    groupedBlocks.push({ htmlBlocks, layout, props: groupedBlockProps });
  return { groupedBlocks, instanceSections, conditionalLogics };
};

export const getContent = (contentItem: HtmlBlock) => {
  if (typeof contentItem === 'string') {
    return (
      <div
        className="bn-default-styles"
        dangerouslySetInnerHTML={{ __html: contentItem }}
      />
    );
  }
  if (React.isValidElement(contentItem)) {
    return <>{contentItem}</>;
  }
  return <></>;
};

export const renderContent = (
  htmlContent: HtmlBlock[],
  blocksLayout: number[],
  deviceType: DeviceType,
) => {
  let currentGridItems: ReactElement[] = [];
  let currentLayoutType = blocksLayout?.[0];

  const grids: ReactElement[] = [];

  const getGridClassNames = (layoutType: number) =>
    classNames(
      'grid gap-4 grid-cols-auto-fit-minmax-200',
      deviceType !== DEVICE_TYPE.MOBILE &&
        (layoutType === 1
          ? 'sm:grid-cols-1'
          : layoutType === 2
            ? 'sm:grid-cols-2'
            : 'sm:grid-cols-3'),
    );

  forEach(htmlContent, (contentItem, index) => {
    const layoutType = blocksLayout[index];
    // If layoutType changes or the current grid is full
    if (layoutType !== currentLayoutType) {
      // Add the current grid to the list of grids
      grids.push(
        <div
          key={`grid-${index}`}
          className={getGridClassNames(currentLayoutType)}
        >
          {currentGridItems}
        </div>,
      );

      // Reset current grid items and update layout type
      currentGridItems = [];
      currentLayoutType = layoutType;
    }

    // Add the contentItem to the current grid
    currentGridItems.push(getContent(contentItem));

    // Handle the last grid which may not be added in the loop
    if (index === htmlContent.length - 1) {
      grids.push(
        <div
          key={`grid-${index}-end`}
          className={getGridClassNames(currentLayoutType)}
        >
          {currentGridItems}
        </div>,
      );
    }
  });

  return grids;
};

export const handleSectionInstances = (
  index: any,
  operation: string,
  groupedContent: FieldData,
  instanceSectionsRef: React.MutableRefObject<FieldData>,
  setFormFileData: (val: FieldData) => void,
  setFormData: (val: FieldData) => void,
  logicsRef: React.MutableRefObject<LogicProps[]>,
  savedkey: string,
) => {
  try {
    const sectionLogics: LogicProps[] = cloneDeep(logicsRef.current) || [];

    const content = cloneDeep(groupedContent);

    const targetItem = content[index];

    const { htmlBlocks, layout, instances } =
      instanceSectionsRef.current[index] || {};

    const repeatCount: number =
      operation === 'add' ? instances + 1 : instances - 1;

    const savedData = getItem(savedkey) || {};

    const repeatArrayWithItem = (
      originalArray: any[],
      itemToAdd: any,
      count: number,
    ) => {
      // Helper to update attrkey and onChange in props to have unique form data values on duplication
      const updateAttrKeyAndOnChange = (item: any, suffix: string) => {
        const attrkey = get(item, 'props.children.props.attrkey');
        const blockId = get(item, 'props.children.props.id');

        if (attrkey) {
          const updatedItem = cloneDeep(item);
          const logics = cloneDeep(logicsRef.current);
          const customBlockType = get(
            item,
            'props.children.props.customBlockType',
          );
          const newAttrKey = `${attrkey}${suffix}`;

          // Update logics to append suffix to actionBlock and targetBlocks
          forEach(logics, (logic: any) => {
            if (isEqual(get(logic, 'actionBlock.label'), attrkey)) {
              set(logic, 'actionBlock.label', newAttrKey);
              set(logic, 'actionBlock.value', newAttrKey);
              // Update targetBlocks
              forEach(get(logic, 'targetBlocks', []), (targetBlock: any) => {
                set(
                  targetBlock,
                  'label',
                  `${get(targetBlock, 'label')}${suffix}`,
                );
                set(
                  targetBlock,
                  'value',
                  `${get(targetBlock, 'value')}${suffix}`,
                );
              });
              sectionLogics.push(logic);
            }
          });

          set(updatedItem, 'props.id', newAttrKey);
          set(updatedItem, 'props.children.props.attrkey', newAttrKey);
          savedData[newAttrKey] &&
            set(
              updatedItem,
              'props.children.props.value',
              savedData[newAttrKey],
            );
          set(updatedItem, 'props.children.props.onChange', (res: any) =>
            onChangeInBlock(
              customBlockType,
              res,
              newAttrKey,
              setFormFileData,
              setFormData,
              { current: logics },
              blockId,
            ),
          );
          return updatedItem;
        }
        return item;
      };

      // Repeat and update items
      const repeatedItems = flatMap(
        times(count, (idx) => {
          const suffix = `_${idx + 1}`;
          return [
            itemToAdd,
            ...map(originalArray, (item) =>
              updateAttrKeyAndOnChange(item, suffix),
            ),
          ];
        }),
      );

      return [
        ...map(originalArray, (item) => updateAttrKeyAndOnChange(item, '')),
        ...repeatedItems,
      ];
    };

    // Updating targetItem based on the repeat count
    targetItem['htmlBlocks'] = repeatArrayWithItem(
      htmlBlocks,
      <hr className="my-4" />,
      repeatCount,
    );
    targetItem['layout'] = repeatArrayWithItem(layout, 1, repeatCount);

    return { targetItem, repeatCount, sectionLogics };
  } catch (e) {
    console.error('handle section instances failed', e);
  }
};
