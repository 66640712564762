import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import store from '@apps/form/src/store';
import { growthbook } from '@apps/form/src/utils/growthbook';
import Home from '@apps/form/src/Pages/Home';
import Editor from '@apps/form/src/Pages/Editor';
import BlockNoteRenderer from '@apps/form/src/containers/BlockNoteRenderer';
import Auth from '@apps/form/src/containers/Auth';

function App() {
  useEffect(() => {
    growthbook.loadFeatures();
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/builder/:id"
              element={
                <Auth>
                  <Editor />
                </Auth>
              }
            />
            <Route
              path="/renderer/:id"
              element={
                <Auth>
                  <BlockNoteRenderer />
                </Auth>
              }
            />
            <Route path="/" element={<Home />} />
          </Routes>
          <ToastContainer />
        </BrowserRouter>
      </Provider>
    </GrowthBookProvider>
  );
}

export default App;
