import React, { memo } from 'react';
import Tooltip from '@apps/form/src/components/Tooltip';
import MarkdownWrapper from '@apps/form/src/components/MarkdownWrapper';

const FieldLabel = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({
    children,
    required = false,
    helperText,
    error = false,
    className,
    tooltip,
    hiddenLabel,
    disabled = false,
    size = 'sm',
    id,
    value,
    name,
  }) => {
    const labelClasses = [
      'flex items-center text-sm mb-2 font-inter font-medium text-stone-900 not-italic',
      hiddenLabel && 'sr-only',
      `text-${size}`,
      disabled && 'group group-hover:cursor-pointer',
      className,
    ]
      .filter(Boolean)
      .join(' ');

    return (
      <>
        <label
          htmlFor={id || name || ''}
          className={labelClasses}
          data-testid="input-label"
        >
          <MarkdownWrapper styles={required && 'required-label'}>
            {children ? children?.toString() : ''}
          </MarkdownWrapper>
          {tooltip && value && (
            <Tooltip tooltipId={value.toString()} content={tooltip} />
          )}
        </label>
      </>
    );
  },
);

export default memo(FieldLabel);
