import { get, has, isArray, isString, map, merge, reduce, set } from 'lodash';
import {
  FieldData,
  initObjProps,
} from '@apps/form/src/containers/BlockNoteRenderer/types';
import { FORM_TYPE } from '@apps/form/src/utils/constant';

export const initobj: initObjProps = {
  accessToken: '',
  assc: '',
  formId: '',
  isDevelopment: false,
  isAndroid: false,
  isIOS: false,
  uniqueFiles: [],
  identity: '',
  previewMode: false,
};

export const initForm = (
  searchParams: URLSearchParams,
  initobj: initObjProps,
) => {
  if (searchParams) {
    if (searchParams.get('accessToken')) {
      initobj['accessToken'] = 'Bearer ' + searchParams.get('accessToken');
    }
    if (searchParams.get('mobile')) {
      initobj['isAndroid'] = true;
    }
    if (searchParams.get('dev')) {
      initobj['isDevelopment'] = true;
    }
    if (searchParams.get('ios')) {
      initobj['isIOS'] = true;
    }
    if (searchParams.get('assc')) {
      initobj['assc'] = searchParams.get('assc');
    }
    if (searchParams.get('formId')) {
      initobj['formId'] = searchParams.get('formId');
    }
  }

  return initobj;
};

export const isFieldEnabled = (element: HTMLElement | null): boolean =>
  element ? !element.hasAttribute('disabled') : true;

export const getUniqueName = (name: string, uniqueNames: string[]) => {
  let count = 0;
  let uniqueName = name;
  while (uniqueNames.includes(uniqueName)) {
    count++;
    uniqueName =
      count === 1
        ? `${uniqueName} 1`
        : `${uniqueName?.split(' ')?.slice(0, -1)?.join(' ')} ${count}`;
  }
  return uniqueName;
};

export const serializeData = (formData: any) => {
  const names: string[] = [];

  return reduce(
    formData,
    (acc: any[], value: any, key: string) => {
      // Check if the field is disabled using DOM
      const element = (document.querySelector(`[id="${key}"]`) ||
        document.querySelector(`[id="checkbox-${key}"]`)) as HTMLElement;

      // Only include the field if it's enabled and has a value
      if (value && isFieldEnabled(element)) {
        const uniqueName = getUniqueName(key, names);
        names.push(uniqueName);
        acc.push({
          label: uniqueName,
          name: uniqueName,
          value: value,
          key: value,
        });
      }
      return acc;
    },
    [],
  );
};

export const transformCheckboxData = (formData: FieldData) =>
  reduce(
    formData,
    (acc, value, key) => {
      if (isArray(value)) {
        // Transform the array into an object
        const transformed = reduce(
          value,
          (result, { label, value }) => ({
            ...result,
            [label]: value,
          }),
          {},
        );

        // Merge transformed object directly into accumulator
        return merge(acc, transformed);
      }

      // Add non-array key-value pairs directly
      return set(acc, key, value);
    },
    {},
  );

export const postMessage = (payload: any, type: string) => {
  if (initobj.isIOS) {
    (window as any).webkit.messageHandlers.iosClient.postMessage(
      JSON.stringify(payload),
    );
  } else if (initobj.isAndroid) {
    if (type === 'formData') {
      // @ts-expect-error types
      AndroidForms.formData(JSON.stringify(payload));
    } else if (type === 'formFile') {
      const formFile = payload?.formFile;
      const { file, fileName, type } = formFile || {};
      // @ts-expect-error types
      AndroidForms.formFile(file, fileName, type);
    }
  } else {
    window.parent.postMessage(payload, '*');
  }
};

export const postFormFile = (
  res: any,
  attrkey: string,
  setFormFileData: (val: FieldData) => void,
  blockId: string,
) => {
  const { file, fileId } = res || {};
  if (!attrkey || !file) return;
  const reader = new FileReader();
  reader.addEventListener(
    'load',
    function () {
      const filePayload = {
        type: 'formFile',
        formFile: {
          type: attrkey,
          file: reader?.result,
          fileName: file?.name,
        },
      };
      postMessage(filePayload, 'formFile');
      setFormFileData({ [blockId]: fileId });
    },
    false,
  );
  if (file) {
    reader.readAsDataURL(file);
  }
};

export const postFormData = (
  formData: FieldData,
  formInstanceId: number,
  formTypeValue?: string,
) => {
  const transformedData = transformCheckboxData(formData);
  const formPayload = {
    type: 'formData',
    formData: serializeData(transformedData),
    formInstanceId,
  };

  if (formTypeValue === FORM_TYPE.EVALUATION) {
    const transformPayload = {
      type: 'formData',
      formData: [], // Empty array for evaluation form
    };
    postMessage(transformPayload, 'formData');
  } else {
    postMessage(formPayload, 'formData');
  }
};

export const getFormattedSaveData = (
  formData: FieldData,
  formFileData: FieldData,
) => {
  const serializedData =
    serializeData({
      ...formData,
      ...formFileData,
    }) || [];

  return (
    map(serializedData, (item: any) => {
      const name = get(item, 'name', '');
      const value = get(item, 'value', '');
      const isFormFileData = has(formFileData, item?.name);
      return {
        key: name,
        value: isString(value) ? value : JSON.stringify(value),
        uuid: name,
        ...(isFormFileData && { file_id: value }),
      };
    }) || []
  );
};
