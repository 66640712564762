import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ROUTE_TYPE } from "utils/constant";

const SectionGroup = ({
  label,
  textAlignment,
  type,
}: {
  label?: string;
  textAlignment?: string;
  type?: string;
}) => {
  const { formState } = useSelector((state: RootState) => state?.form) || {};
  if (formState === ROUTE_TYPE.RENDERER && !label) return null;

  return (
    <div
      className="w-full text-lg font-medium flex"
      style={{ justifyContent: textAlignment }}
    >
      {label || `------ section group ${type ? "ended" : "started"} ------`}
    </div>
  );
};

export default SectionGroup;
