import CheckboxTree from '@apps/form/src/components/Tools/Checkbox/CheckboxTree';
import DatePicker from '@apps/form/src/components/Tools/DatePicker';
import Input from '@apps/form/src/components/Tools/Input';
import RadioButtonGroup from '@apps/form/src/components/Tools/RadioButtonGroup';
import Select from '@apps/form/src/components/Tools/Select';
import Text from '@apps/form/src/components/Tools/Text';
import TextArea from '@apps/form/src/components/Tools/TextArea';
import File from '@apps/form/src/components/Tools/File';
import Button from '@apps/form/src/components/Button';
import Image from '@apps/form/src/components/Tools/Image';
import PhotoCapture from '@apps/form/src/components/Tools/PhotoCapture';
import SectionGroup from '@apps/form/src/components/Tools/SectionGroup';
import { customBlocksTypes } from '@apps/form/src/components/Tools/CustomBlocks/utils';

export const getElement = (type: string, id?: string) => {
  switch (type) {
    case customBlocksTypes.input:
      return <Input type="text" labelSize="sm" />;
    case customBlocksTypes.number:
      return <Input type="number" labelSize="sm" />;
    case customBlocksTypes.email:
      return <Input type="email" labelSize="sm" />;
    case customBlocksTypes.checkbox:
      return <CheckboxTree />;
    case customBlocksTypes.radio:
      return <RadioButtonGroup />;
    case 'text':
      return <Text />;
    case customBlocksTypes.datepicker:
      return <DatePicker />;
    case customBlocksTypes.select:
      return (
        <Select
          labelKey="label"
          primaryKey="value"
          isDisabled
          isClearable
          isSearchable
        />
      );
    case customBlocksTypes.textarea:
      return <TextArea labelSize="sm" />;
    case customBlocksTypes.file:
      return <File />;
    case customBlocksTypes.image:
      return <Image />;
    case customBlocksTypes.photoCapture:
      return <PhotoCapture />;
    case customBlocksTypes.sectionStart:
      return <SectionGroup />;
    case customBlocksTypes.sectionEnd:
      return <SectionGroup type={customBlocksTypes.sectionEnd} />;
    case 'button':
      return <Button />;
    default:
      return <></>;
  }
};
