import { configureStore } from '@reduxjs/toolkit';
import { authServiceApi } from '@apps/form/src/store/services/authService';
import { updateFormBuilderApi } from '@apps/form/src/store/services/updateFormBuilder';
import rootReducer from '@apps/form/src/store/reducers';

export const store = configureStore({
  reducer: {
    form: rootReducer,
    [authServiceApi.reducerPath]: authServiceApi.reducer,
    [updateFormBuilderApi.reducerPath]: updateFormBuilderApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authServiceApi.middleware,
      updateFormBuilderApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
