export const prefillSupportedFields = [
  'input',
  'number',
  'email',
  'checkbox',
  'textarea',
  'select',
  'radio',
  'datepicker',
  'file',
];
