import React from 'react';

export type ImageProps = {
  previewWidth?: number | string;
  url?: string;
  altText?: string;
  textAlignment?: string;
};

const Image = ({ url, previewWidth, altText, textAlignment }: ImageProps) => (
  <div className="w-full flex" style={{ justifyContent: textAlignment }}>
    <img src={url} width={Number(previewWidth)} alt={altText || 'img'} />
  </div>
);

export default Image;
