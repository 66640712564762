import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { FieldData } from '@apps/form/src/containers/BlockNoteRenderer/types';
import { setItem } from '@apps/form/src/utils/store';
import { ROUTE_TYPE } from '@apps/form/src/utils/constant';
import {
  updateCandidateDetails,
  updateFaceAuthDetails,
  updateFileUploadStatus,
  updateFormData,
  updateFormFileData,
  updateFormState,
} from '@apps/form/src/store/actions';
import { FormState, UpdateFormDataPayload } from '@apps/form/src/store/types';

const initialState: FormState = {
  formData: {},
  formFileData: {},
  formState: ROUTE_TYPE.BUILDER,
  candidateDetails: {},
  faceAuthDetails: {},
  fileUploadStatus: {},
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(
    updateFormData,
    (state, action: PayloadAction<UpdateFormDataPayload>) => {
      state.formData = { ...state.formData, ...action.payload.fieldValues };
      setItem(action.payload.savedKey, state.formData); // store form data in localstorage
    },
  );
  builder.addCase(
    updateFormFileData,
    (state, action: PayloadAction<FieldData>) => {
      state.formFileData = { ...state.formFileData, ...action.payload };
    },
  );
  builder.addCase(updateFormState, (state, action) => {
    state.formState = action.payload;
  });
  builder.addCase(updateCandidateDetails, (state, action) => {
    state.candidateDetails = action.payload;
  });
  builder.addCase(updateFaceAuthDetails, (state, action) => {
    state.faceAuthDetails = { ...state.faceAuthDetails, ...action.payload };
  });
  builder.addCase(updateFileUploadStatus, (state, action) => {
    state.fileUploadStatus = { ...state.fileUploadStatus, ...action.payload };
  });
});

export default reducer;
