import React from 'react';
import classNames from 'classnames';
import { createReactBlockSpec } from '@blocknote/react';
import { InlineContentSchema, StyleSchema } from '@blocknote/core';
import { pickBy } from 'lodash';
import * as layoutIcons from '@apps/form/src/images/LayoutIcons';
import { getElement } from '@apps/form/src/utils/getElement';
import LogicBlock, {
  options,
} from '@apps/form/src/components/Tools/LogicBlock';
import {
  customBlocksTypes,
  logicSupported,
} from '@apps/form/src/components/Tools/CustomBlocks/utils';

type LayoutIconKeys = 'layout_2' | 'layout_3';

type EditorItem = {
  type: string;
  props: {
    attrkey?: string;
    label?: string;
    options?: any;
    hide?: boolean;
  };
};

const CreateBlock = ({
  type,
  propSchema,
}: {
  type: string;
  propSchema: object;
}) =>
  createReactBlockSpec<any, InlineContentSchema, StyleSchema>(
    {
      type,
      propSchema,
      content: 'none',
    },
    {
      render: (props) => {
        const { block, editor } = props || {};
        const { props: blockProps } = block || {};
        const { layout, ...filteredProps } =
          pickBy(blockProps, (value) => value !== null) || {};

        const processItems = (item: EditorItem) => {
          const val = item?.props?.attrkey || item?.props?.label;
          const hide = item?.props?.hide;
          return val ? { label: val, value: val, hide } : null;
        };

        const getSupportedBlocks = () =>
          editor.document
            .filter((item) => logicSupported.includes(item?.type))
            .map(processItems)
            .filter((item) => item !== null);

        const getBlocks = () =>
          editor.document.map(processItems).filter((item) => item !== null);

        const getBlockValues = () =>
          editor.document
            .filter((item) => logicSupported.includes(item?.type))
            .reduce<{ [key: string]: any }>((acc, item) => {
              const label = item?.props?.attrkey || item?.props?.label;
              if (label) acc[label] = item?.props?.options;
              return acc;
            }, {});

        const onActionBlockSelect = (selected: options) => {
          editor.updateBlock(block, {
            props: {
              actionBlock: selected,
              actionBlockOptions: null,
              targetBlocks: [],
            },
          });
        };

        const onActionBlockOptionsSelect = (selected: options) => {
          editor.updateBlock(block, {
            props: {
              actionBlockOptions: Array.isArray(selected)
                ? selected
                : [selected],
            },
          });
        };

        const onTargetBlockSelect = (selected: options) => {
          editor.updateBlock(block, { props: { targetBlocks: selected } });
        };

        const onOperationSelect = (selected: options) => {
          editor.updateBlock(block, {
            props: { operation: selected, targetBlocks: [] },
          });
        };

        const onOperatorSelect = (selected: options) => {
          editor.updateBlock(block, { props: { operator: selected } });
        };

        const icon =
          layoutIcons[`layout_${layout?.value}` as LayoutIconKeys] || null;

        return (
          <div className={classNames('mb-3', blockProps?.hide && 'opacity-50')}>
            {type !== customBlocksTypes.logic ? (
              <div className="flex">
                <div className="min-w-96 max-w-full">
                  {React.cloneElement(getElement(type), {
                    ...filteredProps,
                    disabled: true,
                    onChange: () => {
                      /**/
                    },
                  })}
                </div>
                {icon && <img src={icon} alt="grid_layout" />}
              </div>
            ) : (
              <LogicBlock
                getSupportedBlocks={getSupportedBlocks}
                getBlocks={getBlocks}
                blockProps={blockProps}
                getBlockValues={getBlockValues}
                onActionBlockSelect={onActionBlockSelect}
                onActionBlockOptionsSelect={onActionBlockOptionsSelect}
                onTargetBlockSelect={onTargetBlockSelect}
                onOperationSelect={onOperationSelect}
                onOperatorSelect={onOperatorSelect}
              />
            )}
          </div>
        );
      },
    },
  );

export default CreateBlock;
