import * as faceapi from 'face-api.js';
import _ from 'lodash';
import { setItem } from '@apps/form/src/utils/store';
import { FACE_DETECTION_MESSAGES } from '@apps/form/src/utils/constant';

export const isDate = (d?: string) => {
  if (!d) return true;
  const date = d.trim();
  const isDateFormat = !(
    date.split('-').filter((el: string) => el === '').length > 0
  );

  if (
    date.split('-').length !== 3 ||
    !isDateFormat ||
    isNaN(Date.parse(date))
  ) {
    return false;
  }

  return true;
};

export const isDuplicate = (arr: any[]) => {
  const labelArr = arr.map((item: any) => item.label);
  const valueArr = arr.map((item: any) => item.value);
  const isDuplicateValue = valueArr.some(
    (item: any, idx: number) => valueArr.indexOf(item) !== idx,
  );
  const isDuplicateLabel = labelArr.some(
    (item: any, idx: number) => labelArr.indexOf(item) !== idx,
  );

  return !(isDuplicateLabel || isDuplicateValue);
};

export const isArrayEqual = (x: any, y: any) =>
  _(x).differenceWith(y, _.isEqual).isEmpty();

const roleMap = ['TENANT_ADMIN', 'EVALUATOR', 'MASTER_RECRUITER', 'RECRUITER'];

export const parseJwt = (token?: string) => {
  if (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0)?.toString(16)}`.slice(-2)}`)
        .join(''),
    );
    return JSON.parse(jsonPayload);
  }
  return {};
};

export const processToken = (access_token: string) => {
  const user = parseJwt(access_token);
  const role_list = _.get(
    user,
    "['https://hasura.io/jwt/claims']['X-Hasura-Allowed-Roles']",
    [],
  );
  const roles = [
    ..._.intersection(roleMap, role_list),
    ..._.difference(role_list, roleMap),
  ];
  const default_role = _.get(roles, '[0]', null);
  const user_id = _.get(
    user,
    "['https://hasura.io/jwt/claims']['X-Hasura-User-Id']",
    null,
  );
  const tenant_id = _.get(
    user,
    "['https://hasura.io/jwt/claims']['X-Hasura-Tenant-Id']",
    null,
  );
  const processed_obj = {
    access_token,
    user,
    default_role,
    roles,
    user_id,
    tenant_id,
  };
  setItem('user_details', processed_obj);
  return processed_obj;
};

export const getSerializedFormData = (
  form_data: { key: string; value: string }[],
) => {
  if (form_data) {
    return Object.fromEntries(
      form_data.map((item) => {
        try {
          return [
            item.key,
            typeof item.value === 'string'
              ? JSON.parse(item.value)
              : item.value,
          ];
        } catch (error) {
          console.log(`Error parsing value for key "${item.key}":`, error);
          return [item.key, item.value];
        }
      }),
    );
  }
  return form_data;
};
export const readFileData = (
  file: any,
  callback: (dataURL: string | ArrayBuffer | null) => void,
) => {
  if (!file) return;
  const reader = new FileReader();
  reader.onload = () => callback(reader?.result);
  reader.readAsDataURL(file);
};

export const detectFace = (
  dataURL: string,
  onDetectionSuccess: any,
  onDetectorFail: () => void,
) => {
  const img = new Image();
  img.src = dataURL;
  img.onload = async () => {
    try {
      const detections = await faceapi
        .detectAllFaces(
          img,
          new faceapi.TinyFaceDetectorOptions({ scoreThreshold: 0.5 }),
        )
        .withFaceLandmarks();
      onDetectionSuccess(detections);
    } catch {
      onDetectorFail();
    }
  };
};

export function onDetectionSuccess(detections: any[]) {
  let message = FACE_DETECTION_MESSAGES.NO_FACE;

  if (detections?.length === 1) {
    const detection = detections[0];
    if (detection?.detection?.score >= 0.5)
      message = FACE_DETECTION_MESSAGES.FACE_DETECTED;
    else message = FACE_DETECTION_MESSAGES.FACE_NOT_CLEAR;
  } else if (detections?.length > 1)
    message = FACE_DETECTION_MESSAGES.MULTIPLE_FACES;

  return { isFaceDetected: detections?.length === 1, message };
}

export const resizeImage = (canvas: any, ctx: any, image: any) => {
  const max_width = 320;
  const max_height = 300;
  let width = image.width;
  let height = image.height;

  if (width > height) {
    if (width > max_width) {
      height *= max_width / width;
      width = max_width;
    }
  } else {
    if (height > max_height) {
      width *= max_height / height;
      height = max_height;
    }
  }

  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(image, 0, 0, width, height);
};
