import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { isArrayEqual } from '@apps/form/src/utils';
import CheckBox from './CheckBox';
import FieldLabel from '@apps/form/src/components/Tools/FieldLabel';
import { handleInvalidField } from '@apps/form/src/components/Tools/Input/inputUtils';

const CheckboxTree = (props: any) => {
  const { options, label, id, value, onChange, required, tooltip, disabled } =
    props;
  const [boxList, setBoxList] = useState(options || []);
  const [validation, setValidation] = useState({
    triggerInvalid: false,
    message: '',
  });
  const inputRef = useRef<any>(null);
  const updateValue = (checked: boolean, _option: any, i: number) => {
    const option = { ..._option };
    const list = [...boxList];
    option.checked = checked;
    list.splice(i, 1, option);
    setBoxList(list);
    setValidation((prev) => ({
      ...prev,
      triggerInvalid: !hasCheckedItems(list),
    }));
  };

  const hasCheckedItems = (list: any) => {
    const checkedItems = list.filter((item: any) => item.checked === true);
    return checkedItems.length > 0;
  };

  const handleInvalid = (event: React.InvalidEvent<HTMLInputElement>) => {
    handleInvalidField(event, setValidation);
  };

  const updateDefaultValues = (_value: any, _options: any) => {
    if (_value && Array.isArray(_value)) {
      const values = _value?.map((value: any) => value.value);
      const list = _options.map((option: any) => ({
        ...option,
        checked: values.includes(option.value),
      }));
      setBoxList(list);
    }
  };

  useEffect(() => {
    const filteredValue = boxList
      ?.filter((option: any) => option?.checked)
      ?.map((option: any) => option?.value)
      ?.join(',');
    onChange && onChange(filteredValue);
  }, [boxList]);

  useEffect(() => {
    value && updateDefaultValues(value, boxList);
  }, [value]);

  useEffect(() => {
    const list = boxList.map((option: any) => ({
      id: option?.id,
      name: option?.name,
      label: option?.label,
      value: option?.option,
    }));
    if (!isArrayEqual(list, options)) {
      const filteredValue =
        value &&
        Array.isArray(value) &&
        value.map((option: any) => option?.value);
      const newOptions = options.map((option: any) => {
        if (filteredValue && filteredValue?.includes(option.value)) {
          return { ...option, checked: true };
        } else return { ...option, checked: false };
      });
      setBoxList(newOptions);
    }
  }, [options]);

  useEffect(() => {
    if (
      required &&
      !(boxList?.filter((option: any) => option.checked).length > 0)
    )
      inputRef.current?.setCustomValidity(`This is a required field`);
    else inputRef.current?.setCustomValidity('');
    setValidation((prev) => ({
      ...prev,
      message: inputRef.current?.validationMessage,
    }));
  }, [boxList]);

  return (
    <div data-testid="checkbox" className="w-full">
      {label && (
        <>
          <input
            ref={inputRef}
            defaultValue={value || ''}
            className="absolute opacity-0 pointer-events-none"
            role="button"
            id={id}
            onInvalid={handleInvalid}
          />
          <FieldLabel required={required} value={label} tooltip={tooltip}>
            {label}
          </FieldLabel>
        </>
      )}
      <div
        className={classNames(
          'flex flex-col gap-2 w-auto',
          disabled && 'opacity-50',
        )}
      >
        {boxList?.map((option: any, i: number) => (
          <CheckBox
            disabled={disabled}
            id={option.label?.replace(/\s+/g, '') + id}
            key={option.label + i}
            {...props}
            option={option}
            label={option.label}
            value={option.value || ''}
            checked={!!option?.checked}
            onChange={(e) => updateValue(e, option, i)}
            required={required && !label}
            inputRef={inputRef}
          />
        ))}
      </div>
      {validation?.triggerInvalid && validation?.message && label && (
        <p
          className=" font-normal text-xs text-rose-800 mt-2 "
          data-testid="input_error_msg"
        >
          {validation?.message}
        </p>
      )}
    </div>
  );
};

export default CheckboxTree;
