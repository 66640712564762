import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import FieldLabel from '@apps/form/src/components/Tools/FieldLabel';
import {
  handleInputValidation,
  handleInvalidField,
} from '@apps/form/src/components/Tools/Input/inputUtils';

const TextArea = (props: any) => {
  const {
    labelSize,
    LeadingIcon,
    TrailingIcon,
    defaultValue,
    error,
    disabled,
    updatable,
    pre_filled,
    leadingAddon,
    placeholder,
    label,
    value = '',
    onChange,
    rows,
    minLength,
    maxLength,
    required,
    tooltip,
    id,
  } = props;
  const textAreaRef = useRef<any>(null);
  const [textAreaValue, setTextAreaValue] = useState<string>('');
  const [validation, setValidation] = useState({
    triggerInvalid: false,
    message: '',
  });

  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleInputValidation(event, setValidation);
  };

  const handleInvalid = (event: React.InvalidEvent<HTMLTextAreaElement>) => {
    handleInvalidField(event, setValidation);
  };

  useEffect(() => {
    required && !textAreaValue
      ? textAreaRef?.current?.setCustomValidity('This is a required field')
      : minLength && textAreaValue?.length < minLength
        ? textAreaRef?.current?.setCustomValidity(
            `This field requires ` + minLength + ` or more characters`,
          )
        : textAreaRef?.current?.setCustomValidity('');
    setValidation((prev) => ({
      ...prev,
      message: textAreaRef?.current?.validationMessage,
    }));
  }, [textAreaValue]);

  useEffect(() => {
    if (value && value !== textAreaValue) {
      setTextAreaValue(value);
    }
  }, [value]);

  return (
    <div className="w-full">
      {(label || required) && (
        <FieldLabel
          id={id}
          value={label}
          required={required}
          tooltip={tooltip}
          size={labelSize}
        >
          {label}
        </FieldLabel>
      )}
      {!updatable && pre_filled ? (
        <span className="text-sm py-2 px-2 h-10 block">{textAreaValue}</span>
      ) : (
        <textarea
          data-testid="textArea"
          id={id}
          rows={rows}
          required={required}
          minLength={minLength}
          maxLength={maxLength}
          ref={textAreaRef}
          defaultValue={defaultValue}
          onInput={handleInput}
          onInvalid={handleInvalid}
          placeholder={placeholder}
          className={classNames(
            'pl-2 pr-0.5 pt-1.5 border w-full max-w-[25rem] font-normal text-sm focus:outline-none resize-none justify-center bg-white',
            LeadingIcon ? 'pl-10' : '',
            TrailingIcon ? 'pr-10' : '',
            error
              ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300'
              : 'focus:ring-gray-500 focus:border-gray-500 border-gray-300 placeholder-gray-300 text-gray-900 ',
            validation?.triggerInvalid &&
              validation?.message &&
              'border-red-700 rounded-md border',
            leadingAddon ? 'rounded-r-md rounded-none' : 'rounded-md',
            (disabled || (!updatable && pre_filled)) && 'opacity-50',
          )}
          onChange={(e) => {
            setTextAreaValue(e.target.value);
            onChange(e.target.value);
          }}
          disabled={disabled || (!updatable && pre_filled)}
          value={textAreaValue}
        />
      )}
      {validation?.triggerInvalid && validation?.message && (
        <p
          className=" font-normal text-xs text-rose-800"
          data-testid="input_error_msg"
        >
          {validation?.message}
        </p>
      )}
    </div>
  );
};

TextArea.defaultProps = {
  labelSize: 'sm',
};
export default TextArea;
