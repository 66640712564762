import React, { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import {
  ClockIcon,
  DocumentDuplicateIcon,
  EllipsisVerticalIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
type MenuOption = {
  label: string;
  icon: any;
  action: () => void;
};
const ThreeDotMenu: React.FC = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const handleClickOutside = () => {
    setIsOpen(false);
  };
  const options: MenuOption[] = props.options || [
    {
      label: 'Form History',
      icon: ClockIcon,
      action: () => console.log('Form History clicked'),
    },
    {
      label: 'Duplicate',
      icon: DocumentDuplicateIcon,
      action: () => console.log('Duplicate clicked'),
    },
    {
      label: 'Delete Form',
      icon: TrashIcon,
      action: () => console.log('Delete Form clicked'),
    },
  ];
  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };
  const handleOptionClick = (action: () => void) => {
    toggleMenu();
    action();
  };
  useOnClickOutside(ref, handleClickOutside);
  return (
    <div
      className="relative inline-block text-left ml-[0.75rem] h-[2.375rem]"
      ref={ref}
    >
      <div>
        <button
          type="button"
          className="flex items-center text-gray-500 hover:text-gray-700"
          onClick={toggleMenu}
          data-testid={'three_dot'}
        >
          <EllipsisVerticalIcon className="w-[1.48725rem] min-h-[2.375rem] text-black" />
        </button>
      </div>
      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1">
            {options.map((option, index) => (
              <button
                key={index}
                type="button"
                data-testid={'three_dot_option_' + index}
                className={classNames(
                  'group w-full flex items-center px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900',
                  index === 2 ? 'text-[#9E2034]' : 'text-[#111827]',
                )}
                onClick={() => handleOptionClick(option.action)}
              >
                <div
                  className={
                    index === 2
                      ? 'mr-2 text-[#9E2034]'
                      : 'mr-2 group-hover:text-gray-500 text-[#111827]'
                  }
                >
                  <option.icon className="w-[1.5rem] h-[1.5rem]" />
                </div>
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ThreeDotMenu;
