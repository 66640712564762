import { FormStateType } from '@apps/form/src/Pages/Editor';
import Button from '../Button';

type PublishButtonType = {
  formState: FormStateType;
  publishFormState: () => void;
  isPublishLoading: boolean;
};
const PublishButton = ({
  formState,
  publishFormState,
  isPublishLoading,
}: PublishButtonType) => (
  <Button
    buttonstyles={'py-3'}
    onClick={() => publishFormState()}
    isSolid={true}
    text={'Publish Form'}
    textStyles={'text-white text-sm font-medium text-center'}
    isLoading={isPublishLoading}
  />
);
export default PublishButton;
