import React from 'react';
import classNames from 'classnames';
import Loader from '@apps/form/src/components/Loader';

type ButtonProps = {
  onClick?: () => void;
  isSolid?: boolean;
  isLoading?: boolean;
  Icon?: React.ElementType;
  text?: string;
  label?: string;
  buttonstyles?: string;
  textStyles?: string;
};

const Button: React.FC<ButtonProps> = ({
  onClick,
  isSolid = false,
  isLoading,
  Icon,
  text,
  label,
  buttonstyles,
  textStyles,
}) => {
  const defaultButtonStyles =
    'w-[105px] h-[38px] rounded-lg justify-center items-center gap-2 inline-flex';
  const computedStyles = classNames(
    defaultButtonStyles,
    buttonstyles,
    isSolid ? 'bg-blue-700 text-white' : 'border border-blue-700 text-blue-700',
  );

  return (
    <div
      className={computedStyles}
      onClick={() => !isLoading && onClick && onClick()}
      data-testid={`button`}
      role="button"
    >
      {isLoading ? (
        isSolid ? (
          <Loader color={'white'} />
        ) : (
          <Loader />
        )
      ) : (
        <div className={textStyles}>
          {Icon && <Icon className="h-4 w-4 flex mr-2 stroke-[2.5px]" />}
          {text || label}
        </div>
      )}
    </div>
  );
};

export default Button;
