import {
  failNotification,
  successNotification,
  warningNotification,
} from './CustomNotification';

// SaveFormInstance
export const successSaveFormInstanceNotification = () =>
  successNotification({
    title: 'Success',
    summary: 'Form instance saved successfully.',
  });

export const failSaveFormInstanceNotification = () =>
  failNotification({
    title: 'Error',
    summary:
      'We encountered an issue processing your submission. Please try again.',
  });

// File Upload
export const successFileUploadNotification = () =>
  successNotification({
    title: 'Success',
    summary: 'File uploaded successfully.',
  });

export const failFileUploadNotification = () =>
  failNotification({
    title: 'Error',
    summary: 'Failed to upload the file.',
  });
