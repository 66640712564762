import React from 'react';
import Markdown from 'react-markdown';
import classNames from 'classnames';

interface MarkdownWrapperProps {
  children: string;
  prose?: boolean;
  styles?: string;
}

const MarkdownWrapper = ({
  children,
  prose = false,
  styles,
}: MarkdownWrapperProps) => (
  <Markdown
    components={{
      a: ({ href, children }) => (
        <a
          href={href}
          target="_blank"
          className="text-blue-400 no-underline"
          rel="noreferrer"
        >
          {children}
        </a>
      ),
    }}
    className={classNames(prose && 'prose max-w-full text-sm', styles)}
  >
    {children}
  </Markdown>
);

export default MarkdownWrapper;
