import { RadioGroup } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { RadioButtonFilled } from './RadioButtonFilled';
import FieldLabel from '@apps/form/src/components/Tools/FieldLabel';
import { handleInvalidField } from '@apps/form/src/components/Tools/Input/inputUtils';

const RadioButtonGroup = (props: any) => {
  const {
    disabled,
    label,
    options,
    onChange,
    value = { value: '', label: '' },
    rowAlign = false,
    required,
    tooltip,
    id,
  } = props;
  const [selectedValue, setSelectedValue] = useState(value);
  const inputRef = useRef<any>(null);
  inputRef?.current?.setCustomValidity(
    required && !selectedValue?.value ? `This is a required field` : '',
  );
  const [validation, setValidation] = useState({
    triggerInvalid: false,
    message: '',
  });

  const handleInvalid = (event: React.InvalidEvent<HTMLInputElement>) => {
    handleInvalidField(event, setValidation);
  };

  const updateValue = (value: any) => {
    onChange(value);
    setSelectedValue(value);
    setValidation((prev) => ({
      ...prev,
      triggerInvalid: value ? false : true,
    }));
  };

  useEffect(() => {
    if (value?.value !== selectedValue?.value) {
      setSelectedValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (required && !selectedValue?.value) {
      inputRef.current?.setCustomValidity(`This is a required field`);
    } else inputRef.current?.setCustomValidity('');
    setValidation((prev) => ({
      ...prev,
      message: inputRef?.current?.validationMessage,
    }));
  }, [selectedValue]);

  return (
    <div className="flex">
      <RadioGroup
        name={label}
        defaultValue={selectedValue ? selectedValue?.value : null}
        value={selectedValue?.value}
        disabled={disabled}
        data-testid="radio"
      >
        {(label || required) && (
          <RadioGroup.Label className="group group-hover:cursor-pointer items-center leading-normal text-sm font-inter font-medium flex mb-[0.5rem]">
            <FieldLabel value={label} required={required} tooltip={tooltip}>
              {label}
            </FieldLabel>
            <input
              ref={inputRef}
              type="radio"
              value={''}
              className="opacity-0 pointer-events-none"
              data-testid={'hidden_input'}
              onInvalid={handleInvalid}
              id={id}
            />
          </RadioGroup.Label>
        )}
        <div
          className={classNames(
            'relative w-full flex flex-wrap gap-1',
            !rowAlign && 'flex-col',
            disabled && 'opacity-50',
          )}
        >
          {options?.length > 0 ? (
            options.map((input: any, index: number) => {
              const isSelected = input.value === selectedValue?.value;
              return (
                <RadioGroup.Option
                  value={input.value}
                  as={Fragment}
                  key={'radio' + index}
                >
                  <div
                    data-testid={`radio-option-${input.value}`}
                    className={`px-[0.5rem] gap-[0.75rem] min-w-36 h-[2.5rem] last:mb-0 flex items-center bg-white`}
                    onClick={() => updateValue(input)}
                  >
                    <div className="flex items-center w-[1.25rem] h-[1.25rem]">
                      <div
                        className={classNames(
                          'flex items-center',
                          isSelected
                            ? 'border-["#0A7AFF"]'
                            : 'border-[#818181]',
                        )}
                      >
                        {isSelected ? (
                          <RadioButtonFilled />
                        ) : (
                          <div className="rounded-[0.625rem] w-[1.25rem] h-[1.25rem] border-[2px] border-solid border-[#818181]"></div>
                        )}
                      </div>
                    </div>
                    <RadioGroup.Label
                      as="span"
                      className={`${
                        isSelected ? 'text-[#0A7AFF]' : 'text-[#111827]'
                      } block text-sm`}
                    >
                      {input.label}
                    </RadioGroup.Label>
                  </div>
                </RadioGroup.Option>
              );
            })
          ) : (
            <div className="border py-1 px-2 mb-2 last:mb-0  rounded-md flex items-center justify-between ">
              <span className="block text-xs opacity-25">Option 1</span>
              <div className="rounded-full w-6 h-6 p-1 border ml-2"></div>
            </div>
          )}
        </div>
        {validation?.triggerInvalid && validation?.message && (
          <p
            className=" font-normal text-xs text-rose-800 mt-2 "
            data-testid="input_error_msg"
          >
            {validation?.message}
          </p>
        )}
      </RadioGroup>
    </div>
  );
};

export default RadioButtonGroup;
