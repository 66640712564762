import axios from 'axios';

export const uploadFileToS3 = (
  presigned_url: string,
  file: string,
  id: number,
  onFileUploadSuccess: (id: number) => void,
  onFileUploadFailure: () => void,
) =>
  axios
    .put(presigned_url, file)
    .then(() => onFileUploadSuccess(id))
    .catch(onFileUploadFailure);
