import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import Select, { components } from 'react-select';
import { get, map } from 'lodash';
import { API_STATUS } from '@apps/form/src/utils/constant';
import { useGetSelectFieldDataQuery } from '@apps/form/src/store/services/updateFormBuilder';
import { RootState } from '@apps/form/src/store';
import Toggle from '@apps/form/src/components/Popover/MenuPopover/ToggleSwitch';
import FieldLabel from '@apps/form/src/components/Tools/FieldLabel';
import { handleInvalidField } from '@apps/form/src/components/Tools/Input/inputUtils';

interface SelectFieldData {
  key: string | number;
  value: string | number;
  id: string | number;
}

interface SelectFieldDataQuery {
  frm_select_field_data: SelectFieldData[];
}

export const reactSelectDefaultStyles = {
  input: (props: any) => ({
    ...props,
    input: {
      boxShadow: 'none',
    },
  }),
  indicatorSeparator: (props: any) => ({
    ...props,
    display: 'none',
  }),
  placeholder: (props: any) => ({
    ...props,
    color: '#333940',
    opacity: 0.4,
    fontWeight: 400,
    fontSize: '14px',
  }),
  option: (props: any, option: any) => ({
    ...props,
    fontSize: '14px',
    backgroundColor: option.isSelected ? '#1d4ed8' : props.backgroundColor,
  }),
  singleValue: (props: any) => ({
    ...props,
    fontSize: '14px',
  }),
  multiValue: (props: any) => ({
    ...props,
    backgroundColor: '#DBEAFE',
    borderRadius: '4px',
    padding: '0px 8px 0px 8px',
    marginRight: '8px',
  }),
  multiValueLabel: (props: any) => ({
    ...props,
    color: '#1D4ED8',
    fontSize: '12px',
    fontWeight: '500',
  }),
  multiValueRemove: (props: any) => ({
    ...props,
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#1D4ED8',
    },
    color: '#1D4ED8',
  }),
  control: (provided: any, state: any) => {
    const borderColor =
      state?.selectProps?.error ||
      state?.selectProps?.validation?.triggerInvalid
        ? '#b91c1c'
        : '#d1d5db';
    return {
      ...provided,
      borderColor,
      borderRadius: '0.375rem',
      boxShadow: 'none !important',
      '&:hover': {
        borderColor:
          state?.selectProps?.error ||
          state?.selectProps?.validation?.triggerInvalid
            ? '#b91c1c'
            : '#9CA3AF',
        cursor: 'pointer',
      },
      '&:focus': {
        borderColor:
          state?.selectProps?.error ||
          state?.selectProps?.validation?.triggerInvalid
            ? '#b91c1c'
            : '#6B7280',
      },
      '&:focus-within': {
        borderColor:
          state?.selectProps?.error ||
          state?.selectProps?.validation?.triggerInvalid
            ? '#b91c1c'
            : '#6B7280',
      },
    };
  },
};

export function DropdownIndicator(props: any) {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className="w-6 h-6" />
    </components.DropdownIndicator>
  );
}

const SelectDropdown = (props: any) => {
  const {
    value,
    onChange,
    fieldEnableChange,
    disabled,
    readOnly,
    isLoading,
    isClearable,
    isSearchable,
    name,
    placeholder,
    options,
    primaryKey,
    labelKey,
    emptyLabel,
    customStyles,
    isMulti,
    error,
    label,
    required,
    hasToggle,
    isEnabled,
    selectFieldType,
    selectParentField,
    tooltip,
    id,
  } = props;
  const formData = useSelector((state: RootState) => state?.form?.formData);
  const parentLabel = get(selectParentField, 'label');
  const parentId = get(formData, `${parentLabel}.id`);

  const [selectedValue, setSelectedValue] = useState(value);
  const [isToggled, setIsToggled] = useState(isEnabled || false);
  const [apiArgs, setApiArgs] = useState<any>(null);
  const [selectOptions, setSelectOptions] = useState<any[]>(options);
  const [validation, setValidation] = useState({
    triggerInvalid: false,
    message: '',
  });

  const handleInvalid = (event: React.InvalidEvent<HTMLInputElement>) => {
    handleInvalidField(event, setValidation);
  };

  const noOptionsMessage = () => emptyLabel;
  const inputRef = useRef<any>(null);
  const formatOptionLabel = (option: any) => get(option, labelKey, option);
  const getOptionValue = (option: any) => get(option, primaryKey, option);
  const controlStyles = (provided: any, state: any) =>
    reactSelectDefaultStyles.control(provided, {
      ...state,
      selectProps: {
        ...state.selectProps,
        error,
        validation,
      },
    });

  const updateValue = (value: any) => {
    onChange?.(value);
    setSelectedValue(value);
    setValidation((prev) => ({
      ...prev,
      triggerInvalid: value ? false : true,
    }));
  };

  const { data: selectFieldData, status } = useGetSelectFieldDataQuery(
    apiArgs,
    { skip: !apiArgs },
  ) as { data: SelectFieldDataQuery; status: string };

  useEffect(() => {
    if (status === API_STATUS.SUCCESS && selectFieldData) {
      const options = map(
        get(selectFieldData, 'frm_select_field_data', []),
        (item) => ({
          label: item?.value,
          value: item?.key,
          id: item?.id,
        }),
      );
      setSelectOptions(options);
    }
  }, [status, selectFieldData]);

  // clear child select value when parent select value changes
  useEffect(() => {
    if (parentLabel) updateValue(null);
  }, [parentId]);

  useEffect(() => {
    if (options) setSelectOptions(options);
  }, [options]);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useLayoutEffect(() => {
    setIsToggled(isEnabled);
  }, [isEnabled]);

  useEffect(() => {
    if (required && !selectedValue?.value)
      inputRef.current?.setCustomValidity(`This is a required field`);
    else inputRef.current?.setCustomValidity('');
    setValidation((prev) => ({
      ...prev,
      message: inputRef?.current?.validationMessage,
    }));
  }, [selectedValue]);

  const onMenuOpen = () => {
    const fieldTypeId = get(selectFieldType, 'id');
    if (fieldTypeId) {
      if (!parentLabel || parentId) {
        setApiArgs({
          id: Number(fieldTypeId),
          ...(parentId && { parentId: Number(parentId) }),
        });
      } else {
        setSelectOptions([]);
      }
    }
  };

  return (
    <div className="w-full">
      <input
        ref={inputRef}
        name={name}
        value={selectedValue}
        defaultValue={value || ''}
        id={id}
        className="absolute opacity-0 pointer-events-none"
        role="button"
        onInvalid={handleInvalid}
      />
      <div className="text-sm relative flex-col" data-testid="select_button">
        {label && (
          <div className="flex justify-between items-center">
            <FieldLabel
              name={name}
              value={label}
              required={required}
              tooltip={tooltip}
              disabled={disabled}
            >
              {label}
            </FieldLabel>
            {hasToggle && (
              <Toggle
                checked={isEnabled}
                onChange={(e: boolean) => {
                  setIsToggled(e);
                  fieldEnableChange && fieldEnableChange(e);
                }}
              />
            )}
          </div>
        )}
        {(!hasToggle || (hasToggle && isToggled)) && (
          <div
            className={classNames('max-w-[25rem]', disabled && 'opacity-50')}
          >
            <Select
              data-testid="select_button"
              value={selectedValue}
              onChange={updateValue}
              isDisabled={disabled || readOnly}
              isLoading={status === API_STATUS.LOADING || isLoading}
              isClearable={isClearable}
              isSearchable={isSearchable}
              name={name}
              placeholder={placeholder}
              options={selectOptions}
              getOptionValue={getOptionValue}
              getOptionLabel={formatOptionLabel}
              formatOptionLabel={formatOptionLabel}
              noOptionsMessage={noOptionsMessage}
              styles={{
                ...reactSelectDefaultStyles,
                ...customStyles,
                control: controlStyles,
              }}
              components={{
                DropdownIndicator,
              }}
              isMulti={isMulti}
              menuPlacement={'auto'}
              onMenuOpen={onMenuOpen}
            />
            {error && <p className="text-red-700 mt-1 text-xs">{error}</p>}
          </div>
        )}
      </div>
      {validation?.triggerInvalid && validation?.message && (
        <p
          className="font-normal text-xs text-rose-800 mt-2"
          id={`${name}-description`}
          data-testid="input_error_msg"
        >
          {validation?.message}
        </p>
      )}
    </div>
  );
};

SelectDropdown.defaultProps = {
  name: 'dropdown',
  isSearchable: false,
  isMulti: false,
};

export default memo(SelectDropdown);
