import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { forEach, get, isEmpty } from 'lodash';
import {
  faSquareMinus,
  faSquarePlus,
} from '@fortawesome/pro-regular-svg-icons';
import { updateFormData } from '@apps/form/src/store/actions';
import { getItem } from '@apps/form/src/utils/store';
import { renderContent } from '@apps/form/src/containers/BlockNoteRenderer/utils/renderHelpers';
import { applyConditionalLogic } from '@apps/form/src/containers/BlockNoteRenderer/utils/conditionalLogic';
import { renderIcon } from '@apps/form/src/containers/BlockNoteRenderer';
import {
  FieldData,
  SectionGroupRenderProps,
} from '@apps/form/src/containers/BlockNoteRenderer/types';

export const SectionGroupRender: React.FC<SectionGroupRenderProps> = ({
  sectionContent,
  deviceType,
  sectionLogics,
  isAddDisabled,
  isSubtractDisabled,
  savedKey,
  handleInstanceUpdate,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const formData = getItem(savedKey);
    if (!isEmpty(formData) && !isEmpty(sectionLogics)) {
      forEach(formData, (val, key) => {
        forEach(sectionLogics, (logic) => {
          if (get(logic, 'actionBlock.label') === key) {
            applyConditionalLogic([logic], key, val?.value);
          }
        });
      });
    }
  }, [sectionLogics]);

  return (
    <>
      {renderContent(
        get(sectionContent, 'htmlBlocks', []),
        get(sectionContent, 'layout', []),
        deviceType,
      )}
      {renderIcon(faSquarePlus, isAddDisabled, () =>
        handleInstanceUpdate('add'),
      )}
      {renderIcon(faSquareMinus, isSubtractDisabled, () => {
        const fieldValues: FieldData = {};
        const { repeatCount, htmlBlocks } =
          handleInstanceUpdate('subtract') || {};

        if (htmlBlocks && typeof repeatCount === 'number') {
          forEach(htmlBlocks, (block) => {
            const blockId = get(block, 'props.id');
            if (blockId) fieldValues[`${blockId}_${repeatCount + 1}`] = null;
          });
        }

        if (!isEmpty(fieldValues))
          dispatch(updateFormData({ savedKey, fieldValues }));
      })}
    </>
  );
};

export default memo(SectionGroupRender);
