import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { gql } from 'graphql-request';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { find, get } from 'lodash';
import { getItem } from '@apps/form/src/utils/store';
import { FORM_STATUS } from '@apps/form/src/utils/constant';
import { getSerializedFormData } from '@apps/form/src/utils';

interface frm_form_data_input {
  key: string;
  value: string;
  file_id?: number;
  uuid: string;
}
export const defaultBlock = {
  type: { label: 'One', value: 'w-full', no_column: 1, category: 'LAYOUT' },
  blocks: [null],
};

export const defaultLayout = [
  {
    type: { label: 'One', value: 'w-full', no_column: 1, category: 'TITLE' },
    blocks: [
      {
        properties: {
          label: 'Submit',
          name: 'button',
        },
        type: 'button',
      },
      {
        properties: {
          size: { label: 'Heading 1', value: 'text-3xl' },
          label: 'Add your label',
          value: 'Form Title',
        },
        type: 'text',
      },
    ],
  },
  { ...defaultBlock },
];

export const rootUrl = process.env.REACT_APP_BASE_URL;

export const updateFormBuilderApi: any = createApi({
  reducerPath: 'form_id',
  baseQuery: retry(
    graphqlRequestBaseQuery({
      url: `${rootUrl}/v1/graphql`,
      prepareHeaders: (headers) => {
        const { access_token, default_role, tenant_id } =
          getItem('user_details') || {};
        if (access_token && tenant_id)
          headers.set('Authorization', `Bearer ${access_token}`);
        if (default_role) headers.set('x-hasura-role', default_role);
        return headers;
      },
    }),
    { maxRetries: 2 },
  ),
  tagTypes: [FORM_STATUS.DRAFT, FORM_STATUS.PUBLISHED],

  endpoints: (builder: any) => ({
    getFormDraft: builder.query({
      query: ({ id }: any) => ({
        document: gql`
            query MyQuery {
              frm_form_by_pk(id: ${id}) {
                form_versions(where: {status: {_in: [DRAFT,PUBLISHED]}}) {
                  structure
                  status
                }
                id
                name
                type
              }
            }
          `,
      }),
      providesTags: [FORM_STATUS.DRAFT],
      transformResponse: (response: any) => {
        const {
          frm_form_by_pk: { name, type, form_versions },
        } = response || {};
        // Prioritize draft version, if available, otherwise fallback to published
        const draftOrPublished =
          find(
            form_versions,
            (version: any) => version?.status === FORM_STATUS.DRAFT,
          ) || form_versions?.[0];
        return { name, type, structure: draftOrPublished?.structure };
      },
    }),
    getFormStructure: builder.query({
      query: ({ id }: { id: number }) => ({
        document: gql`
            query {
              frm_form_by_pk(id: ${id}) {
                id
                form_versions(where: {status: {_eq: PUBLISHED}}) {
                  id
                  structure
                  status
                  version
                }
                type
              }
            }
          `,
      }),
      transformResponse: (response: any) => {
        const {
          frm_form_by_pk: { type, form_versions },
        } = response || {};
        return {
          type,
          structure: get(form_versions, '0.structure'),
          form_version_id: get(form_versions, '0.id'),
        };
      },
      providesTags: [FORM_STATUS.PUBLISHED],
    }),
    getSelectFieldTypes: builder.query({
      query: () => ({
        document: gql`
          query {
            frm_select_field_type {
              id
              name
            }
          }
        `,
      }),
    }),
    getSelectFieldData: builder.query({
      query: ({ id, parentId }: { id: number; parentId: number }) => ({
        document: parentId
          ? gql`
              query ($id: Int!, $parentId: Int!) {
                frm_select_field_data(
                  where: {
                    select_field_type_id: { _eq: $id }
                    parent_id: { _eq: $parentId }
                  }
                ) {
                  key
                  value
                  id
                }
              }
            `
          : gql`
              query ($id: Int!) {
                frm_select_field_data(
                  where: { select_field_type_id: { _eq: $id } }
                ) {
                  key
                  value
                  id
                }
              }
            `,
        variables: { id, parentId },
      }),
    }),
    createForm: builder.mutation({
      query: ({ name, type }: { name: string; type: string }) => ({
        document: gql`
          mutation ($name: String!, $type: String!) {
            frm_create_form(name: $name, type: $type) {
              data {
                id
                name
                type
              }
            }
          }
        `,
        variables: {
          name,
          type,
        },
      }),
    }),
    updateForm: builder.mutation({
      query: ({ form_id, structure }: { form_id: string; structure: any }) => ({
        document: gql`
          mutation ($form_id: Int!, $structure: jsonb!) {
            frm_update_form_version(form_id: $form_id, structure: $structure) {
              form_id
              structure
            }
          }
        `,
        variables: {
          form_id,
          structure: structure,
        },
      }),
    }),
    publishForm: builder.mutation({
      query: ({ form_id }: { form_id: number }) => ({
        document: gql`
          mutation ($form_id: Int!) {
            frm_publish_form(form_id: $form_id) {
              form_id
              status
              structure
            }
          }
        `,
        variables: {
          form_id,
        },
      }),
      invalidatesTags: [FORM_STATUS.PUBLISHED],
    }),
    saveFormInstance: builder.mutation({
      query: ({
        formFieldsData,
        formId,
        model,
        model_id,
        saveFormSuccessCallback,
        saveFormFailureCallback,
      }: {
        formFieldsData: frm_form_data_input[];
        formId: number;
        model?: string;
        model_id?: number;
        saveFormSuccessCallback: (id: number) => void;
        saveFormFailureCallback: () => void;
      }) => ({
        document: gql`
          mutation (
            $formFieldsData: [frm_form_data_input!]!
            $formId: Int!
            $model: String
            $model_id: Int
          ) {
            frm_form_instance_save(
              form_data: $formFieldsData
              form_id: $formId
              model: $model
              model_id: $model_id
            ) {
              id
            }
          }
        `,
        variables: {
          formFieldsData,
          formId,
          model,
          model_id,
        },
      }),
      onQueryStarted: async (arg: any, { queryFulfilled }: any) => {
        const { saveFormSuccessCallback, saveFormFailureCallback } = arg || {};
        try {
          const response = await queryFulfilled;
          const saveInstanceId = get(
            response,
            'data.frm_form_instance_save.id',
          );
          if (saveInstanceId) saveFormSuccessCallback?.(saveInstanceId);
          else saveFormFailureCallback?.();
        } catch {
          saveFormFailureCallback?.();
        }
      },
    }),
    uploadFile: builder.mutation({
      query: ({
        fileName,
        fileType,
        user_id,
        uploadCallback,
      }: {
        fileName: string;
        fileType: string;
        user_id: number;
        uploadCallback: () => void;
      }) => ({
        document: gql`
          mutation fileUpload(
            $fileName: String!
            $fileType: String!
            $user_id: Int!
          ) {
            file_upload_file(
              extension: $fileType
              file_type_id: 46
              original_name: $fileName
              owner_id: $user_id
            ) {
              id
              presigned_url
            }
          }
        `,
        variables: {
          fileName,
          fileType,
          user_id,
        },
      }),
      async onQueryStarted(arg: any, { queryFulfilled }: any) {
        const { uploadCallback } = arg || {};
        try {
          const data = await queryFulfilled;
          uploadCallback?.(data);
        } catch (error) {
          console.error('Error uploading file:', error);
          uploadCallback?.();
        }
      },
    }),
    fetchCandidateDetails: builder.query({
      query: () => ({
        document: gql`
          query candidateDetails {
            can_candidate {
              first_name
              last_name
              external_id
              email
              middle_name
              phone_number
              id
              country
            }
          }
        `,
      }),
    }),
    getFormInstance: builder.query({
      query: ({ form_version_id }: { form_version_id: number }) => ({
        document: gql`
          query get_frm_form_instance($form_version_id: Int!) {
            frm_form_instance(
              where: { form_version_id: { _eq: $form_version_id } }
              order_by: { id: desc }
            ) {
              id
              form_version_id
              form_data
            }
          }
        `,
        variables: {
          form_version_id,
        },
      }),
      transformResponse: (response: any) => {
        const { frm_form_instance } = response || {};
        const form_data = get(frm_form_instance, '0.form_data');
        return getSerializedFormData(form_data);
      },
    }),
    getFileDetails: builder.query({
      query: ({ file_id }: { file_id: number }) => ({
        document: gql`
          query get_file_download_file($file_id: Int!) {
            file_download_file(id: $file_id) {
              id
              original_filename
              resource_url
              key
              expires_in
            }
          }
        `,
        variables: {
          file_id,
        },
      }),
      transformResponse: (response: any) => {
        const { file_download_file } = response || {};
        return file_download_file;
      },
    }),
    createAuthRequest: builder.mutation({
      query: ({
        file,
        file_name,
        candidate_id,
      }: {
        file: string;
        file_name: string;
        candidate_id: number;
      }) => ({
        document: gql`
          mutation createFaceAuthRequest(
            $file: String!
            $file_name: String!
            $candidate_id: Int
          ) {
            ca_create_auth_request(
              file: $file
              file_name: $file_name
              candidate_id: $candidate_id
            ) {
              data
              error_message
              success
            }
          }
        `,
        variables: {
          file,
          file_name,
          candidate_id,
        },
      }),
    }),
  }),
});

export const {
  useGetFormDraftQuery,
  useGetFormStructureQuery,
  useGetSelectFieldTypesQuery,
  useGetSelectFieldDataQuery,
  useCreateFormMutation,
  useUpdateFormMutation,
  usePublishFormMutation,
  useSaveFormInstanceMutation,
  useUploadFileMutation,
  useFetchCandidateDetailsQuery,
  useGetFormInstanceQuery,
  useGetFileDetailsQuery,
  useCreateAuthRequestMutation,
} = updateFormBuilderApi;
