import React, { memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import Tab from './Tab';
const Tabs = (props: any) => {
  const { tabs, defaultTab = 0, onChange } = props;
  const [selectedTab, updateSelectedTab] = useState(defaultTab);
  const currentTab = selectedTab;
  const handleTabChange = useCallback(
    (value: any) => {
      updateSelectedTab(value);
    },
    [updateSelectedTab],
  );
  useEffect(() => {
    if (onChange) {
      onChange(selectedTab, currentTab);
    }
  }, [onChange, currentTab, selectedTab]);

  if (tabs && Array.isArray(tabs) && tabs.length) {
    return (
      <div className="flex flex-col overflow-hidden content-end ">
        <nav
          aria-label="Tabs"
          className="flex end pb-2 justify-center"
          data-testid={'nav'}
        >
          <div className={classNames('flex overflow-hidden')}>
            <div className="gap-[2rem] whitespace-nowrap scroll-smooth scrollbar-hide flex items-end h-max">
              {tabs.map((tab) => (
                <Tab
                  key={tab.id}
                  tab={tab}
                  selectedTab={selectedTab}
                  handleTabChange={handleTabChange}
                  data-testid={`tab_${tab.id}`}
                />
              ))}
            </div>
          </div>
        </nav>
      </div>
    );
  }
  return <></>;
};

export default memo(Tabs);
