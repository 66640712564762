import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@apps/form/src/store';
import { ROUTE_TYPE } from '@apps/form/src/utils/constant';
import classNames from 'classnames';

const SectionGroup = ({
  attrkey,
  hide,
  label,
  textAlignment,
  type,
}: {
  attrkey?: string;
  label?: string;
  textAlignment?: string;
  type?: string;
  hide?: boolean;
}) => {
  const { formState } = useSelector((state: RootState) => state?.form) || {};
  if (formState === ROUTE_TYPE.RENDERER && !label) return null;

  return (
    <div
      id={attrkey}
      className={classNames(
        'w-full text-lg font-medium flex',
        formState === ROUTE_TYPE.RENDERER && hide && 'hidden',
      )}
      style={{ justifyContent: textAlignment }}
    >
      {label || `------ section group ${type ? 'ended' : 'started'} ------`}
    </div>
  );
};

export default SectionGroup;
